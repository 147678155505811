<!-- 
author:张洪志
description: 基金详情-公告
 -->
<template>
<section class="fund-detail-5" title="基金公告">
    <div class="tab btn-orange-light">
        <span :class="['btn',{'current':tabIndex===1}]" @click="changeTab(1)">全部</span>
        <span :class="['btn',{'current':tabIndex===index+2}]" @click="changeTab(index+2,item.dictValue)" v-for="(item,index) in fund_ann_type" :key="item.dictValue">{{item.dictLabel}}</span>
    </div>
    <div class="pannel">
        <p class="flex-between" v-for="item in fund_notices" :key="item.id">
          <router-link :to="{name:'FundNotice',params:{id:item.id}}">{{item.infotitle}}</router-link>
          <span class="gray">{{(item.infopubldate||'').$formatDate()}}</span>
        </p>
    </div>
    <div class="pager">
        <page-bar :display="5" :total="pageTotal" @click="goPage" />
    </div>
</section>
</template>

<script>
import PageBar from '@/components/Pagebar.vue'
import { noticeList } from '@/api/fund.js'
export default {
    name: 'FundDetail_5',
    components: {
      PageBar
    },
    props: {
      fundCode: String,
    },
    data() {
      return {
        tabIndex: 1,
        page: 1,
        pageTotal: 0,
        fund_ann_type: [],
        notice_type: '',
        fund_notices:[]
      }
    },
    created() {
      this.initDicts()
      this.getList()
    },
    methods: {
      initDicts() {
        this.getDicts('fund_ann_type').then(r1 => {
          this.fund_ann_type = r1.data;
        })
      },
      getList() {
        noticeList(this.page,15,this.notice_type,this.fundCode).then(result => {
          this.pageTotal = result.data.totalPage
          this.fund_notices = result.data.data
        })
      },
      /** 公告类型切换 */
      changeTab(index,type) {
        this.tabIndex = index
        this.notice_type = type
        this.getList(type)
      },
      /** 页跳转 */
      goPage(page) {
        this.page = page
        this.getList()
      }
    }
}
</script>

<style lang="less" scoped>
@gray: #999;
@orange: #CE9B63;

.gray {
    color: @gray;
}

.orange {
    color: @orange;
}

.btn-orange-light {
    background: #FFF5EA;
    color: #C59660;
    border-radius: 3px;
}

.tab {
    padding: 5px 40px 0 40px;
    font: 16px/2.5em "microsoft yahei";
    display: flex;
    margin-top: 30px;

    &>span {
        padding: 0 2em;
        border-radius: 5px 5px 0 0;

        &.current {
            background: #fff;
        }
    }
}

.pannel {
    padding: 20px 20px 0 20px;
    line-height: 3em;

    &>p {
        height: 3em;
        overflow: hidden;

        &>a:first-child {
            width: 70em;
        }
    }
}

.pager {
    padding: 20px;
    text-align: right;
}

.table {
    text-align: center;
    font-size: 16px;
    line-height: 3em;
    box-shadow: 0 4px 8px #ddd;
    margin-bottom: 40px;
}
</style>
