import { render, staticRenderFns } from "./FundDetail-7.vue?vue&type=template&id=3ea1f8b8&scoped=true&"
import script from "./FundDetail-7.vue?vue&type=script&lang=js&"
export * from "./FundDetail-7.vue?vue&type=script&lang=js&"
import style0 from "./FundDetail-7.vue?vue&type=style&index=0&id=3ea1f8b8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea1f8b8",
  null
  
)

export default component.exports