<!-- 
author:张洪志
description: 基金详情-分红
 -->
<template>
  <section class="fund-detail-7" title="基金分红">
    <div v-if="dividendsInfo.data && dividendsInfo.data.length > 0">
      <div class="title">
        <span class="orange ico ico-block"></span>
        <span>分红配送</span>
      </div>
      <div class="table">
        <p class="head gray-bg flex-between">
          <span>分红年度</span>
          <span>权益登记</span>
          <span>除息日</span>
          <span>派息日</span>
          <span>红利再投资</span>
          <span>每10份收益单位派息(元)</span>
        </p>
        <p class="flex-between" v-for="item in dividendsInfo.data" :key="item.id">
          <span>{{ item.div_year }}</span>
          <span>{{ item.redate }}</span>
          <span>{{ item.exrightdate }}</span>
          <span>{{ item.executedate }}</span>
          <span>{{ item.reinvestday }}</span>
          <span class="red">{{ item.dividendratiobeforetax }}元</span>
        </p>
      </div>
    </div>
    <div class="no-data" v-if="!dividendsInfo.data || dividendsInfo.data.length === 0"></div>
  </section>
</template>

<script>
import { fundSurvey } from '@/api/fundDetail';
export default {
  name: 'FundDetail_7',
  props: {
    fundCode: String
  },
  data() {
    return {
      dividendsInfo: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        fundCode: this.fundCode
      };
      fundSurvey(params).then(res => {
        const {
          code,
          data: { dividendsInfo }
        } = res;
        if (code == 200) {
          this.dividendsInfo = dividendsInfo;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@red: #d43f3f;
@gray-bg: #f3f4f6;
@orange: #ce9b63;

.red {
  color: @red;
}

.gray-bg {
  background: @gray-bg;
}
.orange {
  color: @orange;
}
.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }
}

.table {
  text-align: center;
  font-size: 15px;
  line-height: 4em;
  box-shadow: 0 4px 8px #ddd;
  margin-bottom: 40px;

  p {
    border-bottom: 1px solid #eee;

    &.head {
      border: none;
      font-size: 14px;
      line-height: 3.5em;
    }

    span {
      width: 15%;
    }

    span:last-child {
      width: 25%;
      font-weight: bold;
    }
  }
}

.no-data {
  background: url(../../../static/img/fund-market/no-rate.png) no-repeat center center;
  height: 432px;
}
</style>
