import { render, staticRenderFns } from "./FundDetail-6.vue?vue&type=template&id=07ae9bcb&scoped=true&"
import script from "./FundDetail-6.vue?vue&type=script&lang=js&"
export * from "./FundDetail-6.vue?vue&type=script&lang=js&"
import style0 from "./FundDetail-6.vue?vue&type=style&index=0&id=07ae9bcb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ae9bcb",
  null
  
)

export default component.exports