<!-- 
author:张洪志
description: 基金详情-交易费率
 -->
<template>
<section class="fund-detail-6" title="交易费率">
  <div class="title">
    <span class="orange ico ico-block"></span>
    <span>买入费率</span>
  </div>
  <div class="table table-2" title="买入费率">
    <dl class="flex-between">
      <dt>适用金额</dt>
      <dd>买入费率</dd>
    </dl>
    <dl class="flex-between" v-for="(item, index) in buyRateArr" :key="index">
      <dt>{{ item.name }}</dt>
      <dd>
        <s v-if="false">{{item.rate}} %</s>
        <span class="orange">{{ item.value }}</span>
      </dd>
    </dl>

  </div>

  <div class="title">
    <span class="orange ico ico-block"></span>
    <span>卖出费率</span>
  </div>
  <div class="table table-2" title="卖出费率">
    <dl class="flex-between">
      <dt>持有日期</dt>
      <dd>卖出费率</dd>
    </dl>
    <dl class="flex-between" v-for="(item, index) in saleRateArr" :key="index">
      <dt>{{ item.name }}</dt>
      <dd class="orange">{{ item.value }}</dd>
    </dl>
  </div>

  <div class="title">
    <span class="orange ico ico-block"></span>
    <span>买入规则</span>
  </div>
  <div class="table table-2" title="买入规则">
    <dl class="flex-between">
      <dt>买入状态</dt>
      <dd>{{ prodTradeRule.subSts }}</dd>
    </dl>
    <dl class="flex-between">
      <dt>买入起点</dt>
      <dd>{{prodTradeRule.subStartAmt}}元</dd>
    </dl>
    <dl class="flex-between">
      <dt>定投起点</dt>
      <dd>{{prodTradeRule.autoInvStartAmt}}元</dd>
    </dl>
    <dl class="flex-between">
      <dt>持仓上限</dt>
      <dd>{{prodTradeRule.positionUperLimt}}</dd>
    </dl>
    <dl class="flex-between">
      <dt>追加起点</dt>
      <dd>{{prodTradeRule.additionalStartAmt}}元</dd>
    </dl>
    <dl class="flex-between">
      <dt>转出份额</dt>
      <dd>{{prodTradeRule.minimumShare}}份</dd>
    </dl>
    <dl class="flex-between">
      <dt>买入确认日</dt>
      <dd>{{prodTradeRule.sellConfirmDate}}</dd>
    </dl>
  </div>

  <div class="title">
    <span class="orange ico ico-block"></span>
    <span>卖出规则</span>
  </div>
  <div class="table table-2" title="卖出规则">
    <dl class="flex-between">
      <dt>卖出状态</dt>
      <dd>开放赎回</dd>
    </dl>
    <dl class="flex-between">
      <dt>卖出起点</dt>
      <dd>10元</dd>
    </dl>
    <dl class="flex-between">
      <dt>转出份额</dt>
      <dd>1份</dd>
    </dl>
    <dl class="flex-between">
      <dt>极速回惠金宝</dt>
      <dd>{{prodTradeRule.fastBackSuper}}</dd>
    </dl>
    <dl class="flex-between">
      <dt>卖出确认日</dt>
      <dd>T+1</dd>
    </dl>
    <dl class="flex-between">
      <dt>基金转换</dt>
      <dd>{{prodTradeRule.fundChangeFlag}}</dd>
    </dl>
  </div>

  <div class="title">
    <span class="orange ico ico-block"></span>
    <span>运作费用</span>
  </div>
  <div class="table table-2" title="运作费用">
    <dl class="flex-between">
      <dt>认/申购费用计算方式</dt>
      <dd>{{prodTradeRule.subFormulaMode}}</dd>
    </dl>
    <dl class="flex-between">
      <dt>销售服务费率</dt>
      <dd>{{ saleRate }}</dd>
    </dl>
    <dl class="flex-between">
      <dt>基金管理费率</dt>
      <dd>{{ managerRate }}</dd>
    </dl>
    <dl class="flex-between">
      <dt>基金托管费率</dt>
      <dd>{{ trusteeRate }}</dd>
    </dl>
    <small class="gray">管理费和托管费从基金资产中每日计提。每个工作日公告的基金净值已扣除管理费和托管费，无需投资者在每笔交易中另行支付</small>
  </div>
</section>
</template>

<script>
import { fundTradeRule } from '@/api/fund.js'
import {getDicts} from '@/api/dict.js'
export default {
  name: 'FundDetail_6',
  props: {
    fundCode: String,
  },
  data() {
    return {
      buyRateArr: [],
      saleRateArr: [],
      prodTradeRule: {},
      
      saleRate: '',
      managerRate: '',
      trusteeRate: '',
      
      prod_subSts:[],
      prod_subFormulaModes:[],
      quickly_huichaobaos:[],
      prod_trage_changes:[]
    }
  },
  created() {
    this.getSubSts().then(() => {
      this.getSubFormulaMode()
      this.getHuichaobao()
      this.getExchange()
      this.getRules()
    })
  },
  methods: {
    /** 获取申购状态 */
    getSubSts() {
      return getDicts('prod_subSts').then(result => {
        if(!result.data) {
          return
        }
        this.prod_subSts = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 认/申购费用计算方式 */
    getSubFormulaMode() {
      return getDicts('prod_subFormulaMode').then(result => {
        if(!result.data) {
          return
        }
        this.prod_subFormulaModes = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 获取极速回超宝 */
    getHuichaobao() {
      return getDicts('quickly_huichaobao').then(result => {
        if(!result.data) {
          return
        }
        this.quickly_huichaobaos = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 获取基金转换 */
    getExchange() {
      return getDicts('prod_trage_change').then(result => {
        if(!result.data) {
          return
        }
        this.prod_trage_changes = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    getRules() {
      fundTradeRule(this.fundCode).then(res => {
        let {
          code,
          data: {
            subRate,
            redeemRate,
            prodTradeRule,
            saleRate,
            managerRate,
            trusteeRate
          }
        } = res;
        if (code == 200) {
          prodTradeRule = prodTradeRule || {};
          prodTradeRule.subSts = (this.prod_subSts.find(m => m.code === prodTradeRule.subSts) || {}).name;
          prodTradeRule.fastBackSuper = (this.quickly_huichaobaos.find(m => m.code === prodTradeRule.fastBackSuper) || {}).name;
          prodTradeRule.subFormulaMode = (this.prod_subFormulaModes.find(m => m.code === prodTradeRule.subFormulaMode) || {}).name;
          prodTradeRule.fundChangeFlag = (this.prod_trage_changes.find(m => m.code === prodTradeRule.fundChangeFlag) || {}).name;
          this.prodTradeRule = prodTradeRule
          subRate = subRate || [];
          this.buyRateArr = Array.from(subRate, m => {
            let _data = m.split(' ');
            let value = _data[1]
            /* 
            let _value = parseFloat(value)
            value = _value < 10 ? value + ' %' : value + ' 元'
            */
            return {
              name: _data[0],
              value
            };
          });
          redeemRate = redeemRate || [];
          this.saleRateArr = Array.from(redeemRate, m => {
            let _data = m.split(' ');
            let value = _data[1]
            /* 
            let _value = parseFloat(value)
            value = _value < 10 ? value + ' %' : value + ' 元'
            */
            return {
              name: _data[0],
              value
            };
          });
          
          if(saleRate && saleRate.length>0) {
            this.saleRate = saleRate[0]
          }
          if(managerRate && managerRate.length>0) {
            this.managerRate = managerRate[0]
          }
          if(trusteeRate && trusteeRate.length>0) {
            this.trusteeRate = trusteeRate[0]
          }
        }

      })
    }
  }
}
</script>

<style lang="less" scoped>
@gray: #999;
@gray-bg: #F3F4F6;
@orange: #CE9B63;

.gray {
  color: @gray;
}

.gray-bg {
  background: @gray-bg;
}

.orange {
  color: @orange;
}

.small {
  font-size: 12px;
}

.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }
}

.table {
  line-height: 3em;
  font-size: 15px;
  border: 1px solid #eee;
  border-width: 1px 1px 0 0;
  margin: 10px 0;

  dl {
    border-bottom: 1px solid #eee;
  }

  dt {
    .gray-bg();
  }

  dd s {
    margin-right: 6px;
  }
}

.table-2 {
  dt {
    width: 30%;
    padding-right: 2em;
    text-align: right;
  }

  dd {
    width: 65%;
    padding-left: 2em;
  }
}

.table-4 {
  dt {
    width: 15%;
    padding-right: 2em;
    text-align: right;
  }

  dd {
    width: 30%;
    padding-left: 2em;
  }
}
</style>
