<!-- 
author:张洪志
description: 基金详情-概况
 -->
<template>
<section class="fund-detail-1" title="基金概况">
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>基金概况</span>
    </div>
    <div class="table2">
        <dl class="flex-between">
            <dt>基金名称</dt>
            <dd>{{fundBase.chiname}}</dd>
            <dt>基金简称</dt>
            <dd>{{ fundBase.secuabbr }}</dd>
        </dl>
        <dl class="flex-between">
            <dt>产品代码</dt>
            <dd>{{fundBase.id}}</dd>
            <dt>发行日期</dt>
            <dd>{{fundBase.issuestartdate}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>产品类型</dt>
            <dd>{{fundBase.issuefundtype_desc}}</dd>
            <dt>基金类型</dt>
            <dd>{{fundBase.fundType}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>基金管理人</dt>
            <dd>{{fundBase.investadvisorname}}</dd>
            <dt>基金托管人</dt>
            <dd>{{fundBase.trusteename}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>基金经理</dt>
            <dd>{{fundBase.manager}}</dd>
            <dt>风险等级</dt>
            <dd>{{fundBase.fundRisk}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>业绩评价标准</dt>
            <dd>{{fundBase.performancebenchmark}}</dd>
            <dt>基金管理费</dt>
            <dd>{{fundBase.man_chargeratedes}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>基金托管费</dt>
            <dd>{{fundBase.tru_chargeratedes}}</dd>
            <dt>销售服务费</dt>
            <dd>{{listData.saleRate&&listData.saleRate[0]||''}}</dd>
        </dl>
    </div>
    <div class="small gray" style="padding-top: 1em;">
        <span>管理费和托管费直接冲基金产品中扣除，具体计算方法及费率结构请参见 </span>
        <a  @click="instructions&&instructions.length>0?oninstructions():treatyerr()"  class="blue">基金《招募说明书》</a>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资目标</span>
    </div>
    <div class="big-txt">
        <span>{{fundBase.investtarget}}</span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资理念</span>
    </div>
    <div class="big-txt ">
        <span class="">{{fundBase.riskreturncharacter}}</span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资范围</span>
    </div>
    <div class="big-txt ">
        <span>
            {{fundBase.investfield}}
        </span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资策略</span>
    </div>
    <div class="big-txt ">
        <span>
            {{fundBase.investorientation}}
        </span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>分红政策</span>
    </div>
    <div class="big-txt ">
        <span>
            {{fundBase.profitdistributionrule}}
        </span>
    </div>
</section>
</template>

<script>
import { fundSurvey } from "@/api/fundDetail";
import { getDicts } from "@/api/dict.js";
import { annAndProt } from "@/api/commom.js";
import {fundTradeRule} from '@/api/fund.js'

export default {
  name: "FundDetail_1",
  props: {
    fundCode: String
  },
  data() {
    return {
      fundTypes: [],
      riskLevels: [],
      fundBase: {},
      listData:{},
      instructions: []
    };
  },
  created() {
    this.getFundType().then(() => {
      this.getRiskLevel().then(() => {
        this.getDetail();
        this.annAndProtdata();
      });
    });
  },
  methods: {
    oninstructions() {
      if (
        this.instructions &&
        this.instructions[0] &&
        this.instructions[0].protUrl
      ) {
        this.toPdf(this.instructions[0].protUrl);
      } else {
        this.tofundnotice(this.instructions[0].id);
      }
    },
    annAndProtdata() {
      annAndProt({
        fundCode: this.fundCode,
        protLik: "4",
        protType: "2",
        status: "1"
      }).then(res => {
        // this.annAndProtlist = res.data;
        let annList = res.data.annList;
        let protList = res.data.protList;
        const len = annList.length;
        const len2 = protList.length;
        for (let i = 0; i < len; i++) {
          if (annList[i].infotitle.includes("说明书")) {
            this.instructions.push(annList[i]);
          }
        }
        for (let i = 0; i < len2; i++) {
          if (protList[i].protNameDesc.includes("说明书")) {
            this.instructions.push(protList[i]);
          }
        }
      });
    },
    /** 获取基金类型 */
    getFundType() {
      return getDicts("sys_fund_type").then(result => {
        if (!result.data) {
          return;
        }
        this.fundTypes = Array.from(result.data, m => ({
          name: m.dictLabel,
          code: m.dictValue
        }));
      });
    },
    /** 获取基金风险级别 */
    getRiskLevel() {
      return getDicts("sys_fund_risklevel").then(result => {
        if (!result.data) {
          return;
        }
        this.riskLevels = Array.from(result.data, m => ({
          name: m.dictLabel,
          code: m.dictValue
        }));
      });
    },
    getDetail() {
      let params = {
        fundCode: this.fundCode
      };
      fundSurvey(params).then(res => {
        const { code, data: { fundBase } } = res;
        if (code == 200) {
          this.fundBase = fundBase || {};
          this.fundBase.fundType = this.fundTypes.find(
            m => m.code === this.fundBase.ofundType
          ).name;
          this.fundBase.fundRisk = this.riskLevels.find(
            m => m.code === this.fundBase.ofundRisklevel
          ).name;
        }
      });
      fundTradeRule(this.fundCode).then(res=>{
        this.listData=res.data
      })
    }
  }
};
</script>

<style lang="less" scoped>
@gray: #bbb;
@gray-bg: #f3f4f6;
@orange: #ce9b63;
@blue: #00aaff;

.gray {
  color: @gray;
}

.gray-bg {
  background: @gray-bg;
}

.orange {
  color: @orange;
}

.blue {
  color: @blue;
}

.btn-orange-light {
  background: #fff5ea;
  color: #c59660;
  border-radius: 3px;
}

.small {
  font-size: 12px;
}

.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }
}

.table2 {
  line-height: 3em;
  font-size: 15px;
  border: 1px solid #eee;
  border-width: 1px 1px 0 0;

  dl {
    border-bottom: 1px solid #eee;
  }

  dt {
    .gray-bg();
    width: 6.5em;
    padding: 0 1em;
  }

  dd {
    width: 460px;
    padding-left: 1em;
  }
}

.big-txt {
  line-height: 2em;
  text-indent: 2em;
}
</style>
